import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { logoutUser } from '@actions';
import ArrowDown from '../components/icons/arrow_down';

const UserMenu = ({user, userMenuVisible, handleUserOnClick}) => {

    const userDisplayName = user ? `${user.first_name} ${user.last_name}` : ``;
    
    return (
        <>
            <div className={`site__user ${userMenuVisible ? 'site__user--active' : ''}`} onClick={handleUserOnClick}>
                <div className="user__name">{userDisplayName}</div>
                <div className="user__avatar" style={{backgroundImage: (user.profile_image_url ? `url(${user.profile_image_url})` : null)}}></div>
                <div className="menu__arrow">{ArrowDown}</div>
            </div>
        </>
    );
};

const UserMenuDrawer = ({handleLogout}) => {
    return (
        <div className="site__drawer__menu site__drawer__menu--user">
            {/* <a className="drawer__menu__link">My Account</a>
            <a className="drawer__menu__link">Manage Admins</a> */}
            <a className="drawer__menu__link" onClick={() => handleLogout()}>Logout</a>
        </div>
    );
};

const MenuDrawer = ({visibility, setVisibility, children}) => {
    
    let timer = startTimer(1000);

    useEffect(() => {
        timer = startTimer(1000);
        return function(){
            endTimer();
        };
    }, []);

    if(!visibility){
        return null;
    }

    function startTimer(time){
        return setTimeout(() => {
            setVisibility(false);
        }, time)
    };

    function endTimer(){
        clearTimeout(timer);
    };

    const handleOnMouseOver = () => {
        endTimer();
    };

    const handleOnMouseOut = () => {
        timer = startTimer(1500);
    };

    return (
        <div onMouseOver={handleOnMouseOver} onMouseOut={handleOnMouseOut}>
            {visibility ? children : null}
        </div>
    );
};

function Header(){

    const dispatch = useDispatch();
    const user = useSelector(state => state.currentUser);
    const currentGym = useSelector(state => state.currentGym);
    const [gymMenuVisible, setGymMenuVisible] = useState(false);
    const [userMenuVisible, setUserMenuVisible] = useState(false);

    const handleLogout = () => {
        dispatch(logoutUser());
    };

    const handleGymOnClick = () => {
        setGymMenuVisible(!gymMenuVisible);
    };

    const handleUserOnClick = () => {
        setUserMenuVisible(!userMenuVisible);
    };

    return (
        <header className="site__header">
            {user && !user.is_email_verified ? <div className="user__attention">Your email address still needs validating, check your email inbox. <a>Click here to resend email.</a></div> : null }
            <div className="site__header__container">
                <div className={`site__branding ${gymMenuVisible ? 'site__branding--active' : ''}`} onClick={handleGymOnClick}>
                    <div className="site__name"><div className="site__name__label">{currentGym ? currentGym.gym_name : 'GymDash'}</div></div>
                </div>
                {user ? <UserMenu user={user} userMenuVisible={userMenuVisible} handleUserOnClick={handleUserOnClick} /> : null }
            </div>
            <MenuDrawer visibility={userMenuVisible} setVisibility={setUserMenuVisible}>
                <UserMenuDrawer handleLogout={handleLogout} />
            </MenuDrawer>
        </header>
    );
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
